import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './auth.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [AuthGuard, AuthService],
})
export class AuthModule {}
