import { Injectable } from '@angular/core';
import { LanguageService } from './shared/services/language.service';

@Injectable()
export class AppLanguageConfig {
  constructor(private languageService: LanguageService) {}

  public load(): Promise<boolean> {
    return this.languageService.init();
  }
}
