import { Component, Inject, OnInit, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Department } from '../../../pages/departments/classes/department';
import { TeacherStatus } from '../../../pages/teacher-statuses/classes/teacher-status';
import { ActionMessageService } from '../../services/action-message.service';
import { DepartmentsService } from '../../../pages/departments/services/departments.service';
import { TeachersService } from '../../../pages/teachers/services/teachers.service';
import { TeacherStatusesService } from '../../../pages/teacher-statuses/services/teacher-statuses.service';
import { AdditionalInfoModalData } from './classes/additional-info-modal-data';
import { forkJoin, Observable } from 'rxjs';
import { ObjectData } from '../../../classes/object-data';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectComponent } from '../select/select.component';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TeacherForm } from '../../../pages/teachers/classes/teacher-form';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'app-additional-info-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SelectComponent,
    SaveButtonComponent,
    MatFormFieldModule,
  ],
  templateUrl: './additional-info-modal.component.html',
  styleUrls: ['./additional-info-modal.component.scss'],
})
export class AdditionalInfoModalComponent implements OnInit {
  title: string = 'Login.ToContinueFillIn';
  isLoading: boolean = true;
  isSaving: boolean = false;

  additionalInfoForm: FormGroup;
  departmentControl: FormControl<number>;
  teacherStatusControl: FormControl<number>;

  departments: Department[] = [];
  teacherStatuses: TeacherStatus[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private actionMessageService: ActionMessageService,
    private departmentsService: DepartmentsService,
    private teacherStatusesService: TeacherStatusesService,
    private teachersService: TeachersService,
    @Optional() private dialogRef: MatDialogRef<AdditionalInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AdditionalInfoModalData
  ) {}

  ngOnInit(): void {
    this.initAdditionalInfoForm();

    const deps: [Observable<ObjectData<Department>>, Observable<ObjectData<TeacherStatus>>] = [
      this.departmentsService.getDepartments().pipe(take(1)),
      this.teacherStatusesService.getTeacherStatuses().pipe(take(1)),
    ];

    forkJoin(deps)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([departments, teacherStatuses]: [ObjectData<Department>, ObjectData<TeacherStatus>]) => {
          this.departments = departments.data;
          this.teacherStatuses = teacherStatuses.data;

          this.isLoading = false;
        },
        error: () => {
          this.dialogRef.close();
        },
      });
  }

  initAdditionalInfoForm(): void {
    this.initFormControls();

    this.additionalInfoForm = this.formBuilder.nonNullable.group({
      departmentId: this.departmentControl,
      teacherStatusId: this.teacherStatusControl,
    });
  }

  initFormControls(): void {
    this.departmentControl = new FormControl<number>(null, [Validators.required]);
    this.teacherStatusControl = new FormControl<number>(null, [Validators.required]);
  }

  save(): void {
    if (this.additionalInfoForm.valid) {
      const additionalInfo: TeacherForm = {
        userId: this.data.id,
        name: this.data.name,
        neptunCode: this.data.eduId,
        departmentId: this.departmentControl.value,
        teacherStatusId: this.teacherStatusControl.value,
      };

      this.isSaving = true;
      this.departmentControl.disable();
      this.teacherStatusControl.disable();

      this.teachersService
        .createTeacher(additionalInfo)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.actionMessageService.showAdditionalInfoSuccess();
            setTimeout(() => {
              this.dialogRef.close();
            }, 2000);
          },
          error: (err: HttpErrorResponse) => {
            if (err.status < 500) {
              this.actionMessageService.showTranslatedErrorMessage(err.error.message);
            }

            setTimeout(() => {
              this.dialogRef.close();
            }, 2000);
          },
        });
    }
  }

  get departmentError(): string {
    if (this.departmentControl.touched && this.departmentControl.hasError('required')) {
      return 'Common.DepartmentRequired';
    }

    return '';
  }

  get teacherStatusError(): string {
    if (this.teacherStatusControl.touched && this.teacherStatusControl.hasError('required')) {
      return 'Common.TeacherStatusRequired';
    }

    return '';
  }
}
