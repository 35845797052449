export interface TableParam {
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: OrderBy;
  searchText: string;
}

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}
