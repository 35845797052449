<div class='app-header'>
    <div class='app-header-content text-center'>
        <div *ngIf="(!sidebarOpened || isOverMode) && !showHeaderOnly" class="header-item">
            <button class="menu-icon me-3" mat-icon-button (click)="onOpenSidebar()">
                <mat-icon class="mat-menu-icon">menu</mat-icon>
            </button>
            <div class="header-sub-item " (click)="goToHome()">
                <img class="me-2 app-header-content-logo" src="assets/image/sze_logo2.svg" alt="logo">
                <span class="header-title">
                {{ 'Common.AppTitle' | translate }}
                </span>
            </div>

        </div>
        <div *ngIf="sidebarOpened && !isOverMode">
            &nbsp;
        </div>
        <div *ngIf="showHeaderOnly" class="header-item">
            <div class="header-sub-item " (click)="goToHome()">
                <img class="me-2 app-header-content-logo" src="assets/image/sze_logo2.svg" alt="logo">
                <span class="header-title">
                {{ 'Common.AppTitle' | translate }}
                </span>
            </div>
        </div>
        <div class="header-item">
            <app-language-switcher class="me-2 me-sm-3"></app-language-switcher>
            <div *ngIf="userData?.selectedRole && !userData?.additionalInfoRequired && !showHeaderOnly"
                 class="me-2 user-name d-flex">
                <div class="d-flex flex-column">
                    {{ userData.user.name }}
                    <div class="user-role">
                        ({{ userData.selectedRole | translateRole | translate }})
                    </div>
                </div>
                <button
                        *ngIf="userData?.selectedRole && !userData?.additionalInfoRequired"
                        class="logout"
                        mat-button
                        (click)="logOut()">
                    <mat-icon>power_settings_new</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>