import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/homepage/homepage.module').then((mod) => mod.HomepageModule),
  },
  {
    path: 'subjects',
    loadChildren: () => import('./pages/subjects/subjects.module').then((mod) => mod.SubjectsModule),
  },
  {
    path: 'subject-versions',
    loadChildren: () =>
      import('./pages/subject-versions/subject-versions.module').then((mod) => mod.SubjectVersionsModule),
  },
  {
    path: 'subject-responsible-teachers',
    loadChildren: () =>
      import('./pages/subject-responsible/subject-responsible.module').then((mod) => mod.SubjectResponsibleModule),
  },
  {
    path: 'subject-version-statuses',
    loadChildren: () =>
      import('./pages/subject-version-statuses/subject-version-statuses.module').then(
        (mod) => mod.SubjectVersionStatusesModule
      ),
  },
  {
    path: 'curriculum-version-statuses',
    loadChildren: () =>
      import('./pages/curriculum-version-statuses/curriculum-version-statuses.module').then(
        (mod) => mod.CurriculumVersionStatusesModule
      ),
  },
  {
    path: 'curriculums',
    loadChildren: () => import('./pages/curriculums/curriculums.module').then((mod) => mod.CurriculumsModule),
  },
  {
    path: 'semesters',
    loadChildren: () => import('./pages/semesters/semesters.module').then((mod) => mod.SemestersModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((mod) => mod.LoginModule),
  },
  {
    path: 'departments',
    loadChildren: () => import('./pages/departments/departments.module').then((mod) => mod.DepartmentsModule),
  },
  {
    path: 'specialisations',
    loadChildren: () =>
      import('./pages/specialisations/specialisations.module').then((mod) => mod.SpecialisationsModule),
  },
  {
    path: 'head-of-specialisations',
    loadChildren: () =>
      import('./pages/head-of-specialisations/head-of-specialisations.module').then(
        (mod) => mod.HeadOfSpecialisationsModule
      ),
  },
  {
    path: 'department-administrators',
    loadChildren: () =>
      import('./pages/department-administrators/department-administrators.module').then(
        (mod) => mod.DepartmentAdministratorsModule
      ),
  },
  {
    path: 'administrators',
    loadChildren: () =>
      import('./pages/administrators/administrators.module').then((mod) => mod.AdministratorsModule),
  },
  {
    path: 'teacher-statuses',
    loadChildren: () =>
      import('./pages/teacher-statuses/teacher-statuses.module').then((mod) => mod.TeacherStatusesModule),
  },
  {
    path: 'teachers',
    loadChildren: () => import('./pages/teachers/teachers.module').then((mod) => mod.TeachersModule),
  },
  {
    path: 'activity-categories',
    loadChildren: () =>
      import('./pages/activity-categories/activity-categories.module').then((mod) => mod.ActivityCategoriesModule),
  },
  {
    path: 'activity-rates',
    loadChildren: () =>
      import('./pages/activity-rates/activity-rates.module').then((mod) => mod.ActivityRatesModule),
  },
  {
    path: 'activity-types',
    loadChildren: () =>
      import('./pages/activity-types/activity-types.module').then((mod) => mod.ActivityTypesModule),
  },
  {
    path: 'requirement-forms',
    loadChildren: () =>
      import('./pages/requirement-forms/requirement-forms.module').then((mod) => mod.RequirementFormsModule),
  },
  {
    path: 'subject-version-semester-statuses',
    loadChildren: () =>
      import('./pages/subject-version-semester-statuses/sub-ver-semester-statuses.module').then(
        (mod) => mod.SubVerSemesterStatusesModule
      ),
  },
  {
    path: 'sites',
    loadChildren: () => import('./pages/sites/sites.module').then((mod) => mod.SitesModule),
  },
  {
    path: 'work-schedules',
    loadChildren: () =>
      import('./pages/work-schedules/work-schedules.module').then((mod) => mod.WorkSchedulesModule),
  },
  {
    path: 'subject-version-semesters',
    loadChildren: () =>
      import('./pages/subject-version-semesters/subject-version-semesters.module').then(
        (mod) => mod.SubjectVersionSemestersModule
      ),
  },
  {
    path: 'bug-reports',
    loadChildren: () => import('./pages/bug-reports/bug-reports.module').then((mod) => mod.BugReportsModule),
  },
  {
    path: 'report-bug',
    loadChildren: () => import('./pages/report-bug/report-bug.module').then((mod) => mod.ReportBugModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then((mod) => mod.ResetPasswordModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then((mod) => mod.UsersModule),
  },
  {
    path: 'specialisation-report',
    loadChildren: () =>
      import('./pages/reports/specialisation-reports/specialisation-reports.module').then(
        (mod) => mod.SpecialisationReportsModule
      ),
  },
  {
    path: 'training-languages',
    loadChildren: () =>
      import('./pages/training-languages/training-languages.module').then((mod) => mod.TrainingLanguagesModule),
  },
  {
    path: 'training-levels',
    loadChildren: () =>
      import('./pages/training-levels/training-levels.module').then((mod) => mod.TrainingLevelsModule),
  },
  { path: '**', redirectTo: 'home' },
];

export const AppRoutingModule = RouterModule.forRoot(routes);
