import { Injectable } from '@angular/core';
import { Language, LanguageConfig } from '../../config/language.config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translate: TranslateService) {}

  init(): Promise<boolean> {
    const defaultLang = LanguageConfig.DEFAULT_LANGUAGE;

    this.translate.setDefaultLang(defaultLang);
    this.translate.addLangs(LanguageConfig.LANGUAGES);

    this.translate.use(this.getCurrentLanguage() ?? defaultLang);
    return new Promise((resolve) => resolve(true));
  }

  saveCurrentLanguage(language: Language): void {
    localStorage.setItem('lang', language);
  }

  getCurrentLanguage(): Language {
    return (localStorage.getItem('lang') as Language) ?? (this.translate.currentLang as Language);
  }

  get currentLang(): Language {
    return this.translate.currentLang as Language;
  }
}
