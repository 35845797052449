import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LanguageService } from './shared/services/language.service';
import { AuthService } from './shared/modules/auth/auth.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter, first, fromEvent, map, merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MainConstants } from './config/app.constant';
import { UserData } from './classes/user-data';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { NgSelectConfig } from '@ng-select/ng-select';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  user: UserData;

  showHeaderAndSidebar: boolean = false;
  showHeaderOnly: boolean = false;
  //  isLoading = true;
  id: number;
  sidebarOpened: boolean = true;
  sidebarMode: MatDrawerMode;

  constructor(
    private languageService: LanguageService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private translateService: TranslateService,
    private ngSelectConfig: NgSelectConfig
  ) {
    this.user = this.authService.getLoggedInUser();
  }

  ngOnInit(): void {
    this.listenForNavigationEndChanges();
    this.sideBarState();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (window.innerWidth <= 920) {
        this.sidebarMode = 'over';
        this.sidebarOpened = false;
      } else {
        this.sidebarMode = 'side';
        this.sidebarOpened = true;
      }
    }, 10);
  }

  sideBarState(): void {
    merge(fromEvent(window, 'resize'))
      .pipe(debounceTime(300))
      .subscribe({
        next: () => {
          if (window.innerWidth <= 920) {
            this.sidebarMode = 'over';
            this.sidebarOpened = false;
          } else {
            this.sidebarMode = 'side';
          }
        },
      });
  }

  onSidebarClose(): void {
    this.sidebarOpened = false;
  }

  onSidebarToggle(isopened: boolean): void {
    this.sidebarOpened = isopened;
  }

  private listenForNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot) {
              return child.snapshot;
            } else {
              return null;
            }
          }

          return null;
        }),
        untilDestroyed(this)
      )
      .subscribe((snapshot: ActivatedRouteSnapshot) => {
        this.showHeaderAndSidebar = !MainConstants.hideHeaderTitles.includes(snapshot.data['title']);
        this.showHeaderOnly = MainConstants.headerOnlyTitles.includes(snapshot.data['title']);

        this.translateService
          .get(snapshot.data['title'])
          .pipe(first())
          .subscribe((translatedTitle) => {
            this.title.setTitle(translatedTitle);
          });
      });
  }

  private setNgSelectConfig(): void {
    this.translateService
      .get(['Common.NoResult', 'Common.Add'])
      .pipe(untilDestroyed(this))
      .subscribe((translations) => {
        this.ngSelectConfig.notFoundText = translations['Common.NoResult'];
        this.ngSelectConfig.addTagText = translations['Common.Add'];
      });
  }
}
