import { Pipe, PipeTransform } from '@angular/core';
import { AvailableLanguages } from '../../config/language.config';

@Pipe({
  standalone: true,
  name: 'langShortNameToLongName',
})
export class LangShortNameToLongNamePipe implements PipeTransform {
  transform(shortName: string): string {
    return AvailableLanguages.find((language) => language.slug === shortName).label;
  }
}
