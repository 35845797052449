<button mat-icon-button
        [matMenuTriggerFor]="flags"
        (menuOpened)="toggleMenuOpenedState()"
        (menuClosed)="toggleMenuOpenedState()"
        class="d-flex align-items-center justify-content-center flag-button">
    <span class="d-flex align-items-center current-flag" [ngClass]="{'dark-colored': isColorDark}">
        <span class="d-flex align-items-center justify-content-between">
            <img
                    class="flag me-1"
                    src="assets/image/{{(currentLanguage$ | async)}}.svg"
                    alt="flag-{{currentLanguage$ |async}}">
           <span class="d-none d-md-flex">
               {{currentLanguage$ | async | langShortNameToLongName |  translate}}
           </span>
        </span>
        <mat-icon *ngIf="!menuOpened">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="menuOpened">keyboard_arrow_up</mat-icon>
    </span>
</button>

<mat-menu #flags="matMenu">
    <button
            *ngFor="let language of availableLanguages"
            mat-menu-item
            (click)="changeLanguage(language.slug)"
            class="d-flex align-items-center">
        <span>{{language.label | translate}}</span>
    </button>
</mat-menu>
