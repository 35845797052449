import { Injectable, Injector } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first, Observable, throwError } from 'rxjs';
import { Endpoints, Routes } from '../../../config/app.constant';
import { UserData } from '../../../classes/user-data';
import { catchError, tap } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { AdditionalInfoModalComponent } from '../../components/additional-info-modal/additional-info-modal.component';
import { SelectRoleComponent } from '../../components/select-role/select-role.component';

@UntilDestroy()
@Injectable()
export class AuthService {
  user: UserData;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private injector: Injector,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  loadUser(): Observable<UserData> {
    return this.httpService.get(Endpoints.me);
  }

  getLoggedInUser(): UserData {
    return this.user;
  }

  checkAuth(): Promise<boolean> {
    const token = window.location.search;
    const router = this.injector.get(Router);

    if (window.location.pathname.includes(Routes.specialisationReport)) {
      return Promise.resolve(true);
    }

    console.log('Check authenticated user');

    if (token.includes('token')) {
      localStorage.setItem('token', token?.slice(7));
    }

    if (localStorage.getItem('token') === '' || !localStorage.getItem('token')) {
      return Promise.resolve(false);
    } else {
      return new Promise((resolve) => {
        this.loadUser().subscribe({
          next: (userData: UserData) => {
            if (userData.loggedIn) {
              this.user = userData;
              if (
                window.location.pathname.includes('login') ||
                window.location.pathname.includes('reset-password')
              ) {
                router.navigate([Routes.home], { queryParams: {} }).then(() => {});
              }

              if (userData.additionalInfoRequired) {
                this.modalService
                  .openModal(AdditionalInfoModalComponent, {
                    data: userData.user,
                    hasBackdrop: false,
                    minHeight: '200px',
                    width: '550px',
                  })
                  .afterClosed()
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      window.location.reload();
                    },
                    error: () => {
                      window.location.reload();
                    },
                  });
              } else if (!userData.selectedRole) {
                this.modalService
                  .openModal(SelectRoleComponent, {
                    hasBackdrop: false,
                    width: '600px',
                  })
                  .afterClosed()
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      window.location.reload();
                    },
                    error: () => {
                      window.location.reload();
                    },
                  });
              }

              resolve(true);
            } else {
              this.logout();
              resolve(false);
            }
          },
          error: () => {
            this.logout();
            resolve(true);
          },
        });
      });
    }
  }

  logout(): void {
    if (this.user) {
      this.httpService
        .getShibboleth(Endpoints.shibLogout)
        .pipe(first())
        .subscribe({
          next: () => {
            this.logoutUserAndNavigate();
          },
          error: () => {
            this.logoutUserAndNavigate();
          },
        });
    } else {
      this.logoutUserAndNavigate();
    }
  }

  logoutUserAndNavigate(): void {
    const router = this.injector.get(Router);

    localStorage.removeItem('token');
    this.user = null;

    if (window.location.pathname === '/reset-password') {
    } else {
      router.navigate([Routes.login]);
    }
  }

  refreshToken(): Observable<Record<string, string>> {
    const http = this.injector.get(HttpService);
    return http.get(`/refresh`).pipe(
      tap(() => {
        console.log('%c Token refresh success', 'color: green');
      }),

      catchError((err) => {
        console.log('%c Token refresh failure', 'color: red');
        this.logout();
        return throwError(err);
      })
    );
  }

  selectRole(role: string): Observable<any> {
    return this.httpService.post(Endpoints.selectRole, { selectedRole: role });
  }
}
