import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessages, GeneralError } from '../../config/app.constant';
import { ToastrService } from 'ngx-toastr';
import { capitalize } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ActionMessageService {
  constructor(private translateService: TranslateService, private toastrService: ToastrService) {}

  showTranslatedErrorMessage(errorMessage: string, entity?: string): void {
    if (entity) {
      entity = this.translateService.instant(entity);
    }

    const error = this.translateService.instant(ErrorMessages[errorMessage] || GeneralError, { entity });

    this.toastrService.error(error);
  }

  showTranslatedManageSuccessMessage(entity: string, isEdit?: boolean): void {
    entity = this.translateService.instant(entity);

    const success: string = capitalize(
      this.translateService.instant(isEdit ? 'Common.EditEntitySuccess' : 'Common.CreateEntitySuccess', { entity })
    );

    this.toastrService.success(success);
  }

  showDeleteSuccessMessage(message: string): void {
    this.toastrService.success(this.translateService.instant(message));
  }

  showResetSuccessMessage(message: string): void {
    this.toastrService.success(this.translateService.instant(message));
  }

  showAdditionalInfoSuccess(): void {
    this.toastrService.success(this.translateService.instant('Teacher.AdditionalTeacherDataSuccess'));
  }

  showSelectRoleSuccess(): void {
    this.toastrService.success(this.translateService.instant('Login.SelectRoleSuccess'));
  }

  showSuccess(message: string): void {
    this.toastrService.success(this.translateService.instant(message));
  }

  showError(message: string): void {
    this.toastrService.error(this.translateService.instant(message));
  }
}
