import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpModule } from './shared/modules/http/http.module';
import { SharedModule } from './shared/shared.module';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpMultiLoaderFactory } from './shared/utils/http-multi-loader.util';
import { LoaderModule } from './shared/modules/loader/loader.module';
import { HeaderComponent } from './shared/components/header/header.component';
import { AuthModule } from './shared/modules/auth/auth.module';
import { AppConfig } from './app.config';
import { HttpService } from './shared/modules/http/http.service';
import { AppLanguageConfig } from './app-language-config';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export function initConfig(appConfig: AppConfig): () => Promise<boolean> {
  return () => appConfig.load();
}

export function initLang(langConfig: AppLanguageConfig): () => Promise<boolean> {
  return () => langConfig.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    SharedModule,
    LoaderModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SidebarComponent,
    HeaderComponent,
    ToastrModule.forRoot({ maxOpened: 1, timeOut: 3000, tapToDismiss: true, closeButton: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpMultiLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
  providers: [
    HttpService,
    AppLanguageConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initLang,
      deps: [AppLanguageConfig],
      multi: true,
    },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
