import { HttpBackend } from '@angular/common/http';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

class HttpLoaderResource implements ITranslationResource {
  suffix = '.json';
  prefix: string;

  constructor(location: string) {
    this.prefix = `./assets/i18n/${location}/`;
  }
}

export const HttpMultiLoaderFactory = (http: HttpBackend): MultiTranslateHttpLoader => {
  return new MultiTranslateHttpLoader(http, [
    new HttpLoaderResource('common'),
    new HttpLoaderResource('login'),
    new HttpLoaderResource('home'),
    new HttpLoaderResource('curriculum'),
    new HttpLoaderResource('subject'),
    new HttpLoaderResource('subject-responsible'),
    new HttpLoaderResource('subject-version-status'),
    new HttpLoaderResource('curriculum-version-status'),
    new HttpLoaderResource('subject-version'),
    new HttpLoaderResource('semester'),
    new HttpLoaderResource('department'),
    new HttpLoaderResource('administrator'),
    new HttpLoaderResource('specialisation'),
    new HttpLoaderResource('teacher-status'),
    new HttpLoaderResource('teacher'),
    new HttpLoaderResource('activity-category'),
    new HttpLoaderResource('requirement-form'),
    new HttpLoaderResource('subject-version-semester-status'),
    new HttpLoaderResource('activity-rate'),
    new HttpLoaderResource('activity-type'),
    new HttpLoaderResource('site'),
    new HttpLoaderResource('work-schedule'),
    new HttpLoaderResource('subject-version-semester'),
    new HttpLoaderResource('bug'),
    new HttpLoaderResource('user'),
    new HttpLoaderResource('report'),
    new HttpLoaderResource('training-level'),
    new HttpLoaderResource('training-languages'),
  ]);
};
