<div class="modal-container position-relative additional-info-modal">
    <div class="modal-containertitle mb-3">
        {{ title | translate }}
    </div>
    <div class="modal-content">
        <form [formGroup]="selectRoleForm" class="modal-containerform">
            <div class="select-wrapper mb-2">
                <app-select
                        [control]="roleControl"
                        [clearable]="false"
                        [placeholder]="'Login.Role'"
                        [searchable]="true"
                        [items]="roles"
                        [required]="true">
                </app-select>
                <mat-error>
                </mat-error>
            </div>
        </form>
        <div class="action-buttons mt-3">
            <app-save-button
                    [disabled]="!this.selectRoleForm.valid || isSaving"
                    (save)="save()"
                    [text]="'Common.Confirm'">
            </app-save-button>
        </div>
    </div>
</div>