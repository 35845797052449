import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../../../../classes/roles';

@Pipe({
  name: 'translateRole',
})
export class TranslateRolePipe implements PipeTransform {
  transform(role: Role): string {
    let roleTranslation: string = '';

    switch (role) {
      case Role.Admin: {
        roleTranslation = 'Login.Admin';
        break;
      }
      case Role.Employee: {
        roleTranslation = 'Login.Employee';

        break;
      }
      case Role.HeadOfSpecialisation: {
        roleTranslation = 'Login.HeadOfSpecialisation';
        break;
      }
      case Role.SubjectResponsibleTeacher: {
        roleTranslation = 'Login.SubjectResponsible';
        break;
      }
      case Role.DepartmentAdministrator: {
        roleTranslation = 'Login.DepartmentAdministrator';
        break;
      }
      default: {
        roleTranslation = 'Login.Anyone';
        break;
      }
    }

    return roleTranslation;
  }
}
