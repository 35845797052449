import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  openModal(componentName: any, dialogConfig?: MatDialogConfig): MatDialogRef<any> {
    return this.dialog.open(componentName, { ...dialogConfig, maxHeight: '675px' });
  }
}
