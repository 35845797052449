import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-save-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss'],
})
export class SaveButtonComponent {
  @Input() text: string = 'Common.Save';
  @Input() disabled: boolean;
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
}
