import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { UserData } from '../../../../classes/user-data';
import { Routes } from '../../../../config/app.constant';
import { Role } from '../../../../classes/roles';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      const user: UserData = this.auth.getLoggedInUser();

      if (user) {
        if (state.url === Routes.login || state.url.includes(Routes.resetPassword)) {
          return resolve(false);
        }

        if (next.data && next.data['authGuardParams']) {
          const authGuardParams = next.data['authGuardParams'];
          if (this.hasAccess(authGuardParams.roles, user)) {
            return resolve(true);
          } else {
            if (
              next?.url[0]?.path &&
              next?.parent?.url[0]?.path &&
              next.url[0]?.path !== next.parent?.url[0]?.path
            ) {
              this.router.navigate([next.parent?.url[0]?.path ?? Routes.home]);
            } else {
              this.router.navigate([Routes.home]);
            }

            return resolve(false);
          }
        }

        console.error('AuthGuard: Missing authGuardParams!');

        return resolve(false);
      }

      this.router.navigate([Routes.login]);

      return resolve(false);
    });

    return true;
  }

  hasAccess(routeRoles: Role[], user: UserData): boolean {
    let hasSpecifiedRole = false;

    if (routeRoles.includes(Role.Anyone)) {
      return true;
    }

    routeRoles.forEach((role) => {
      if (user.selectedRole === role) {
        hasSpecifiedRole = true;
      }
    });

    return hasSpecifiedRole;
  }
}
