import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownPosition, NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent {
  @Input() virtualScroll: boolean = false;
  @Input() addTag: boolean = false;
  @Input() appendTo: string = 'body';
  @Input() blurAfterSelect = true;
  @Input() label: string = 'name';
  @Input() value: string = 'id';
  @Input() clearable: boolean = true;
  @Input() notFoundText = 'Common.NoResults';
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() control: FormControl;
  @Input() hideSelected: boolean = false;
  @Input() items: any[] = [];
  @Input() isLoading: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() placeholder: string;
  @Input() required: boolean = false;
  @Input() searchable: boolean = true;
  @Output() add: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;
}
