<app-loader></app-loader>

<mat-drawer-container *ngIf="showHeaderAndSidebar && !showHeaderOnly" class="mat-drawer-container" autosize>
    <mat-drawer
        #drawer
        class="tw-drawer mat-drawer-sidenav"
        [mode]="sidebarMode"
        [opened]="sidebarOpened"
        (closed)="onSidebarClose()">
        <app-sidebar [drawer]="drawer" (toggleSidebar)="onSidebarToggle($event)"></app-sidebar>
    </mat-drawer>

    <main id="main-body" class="mat-drawer-content">
        <header class="sticky-top bg-white">
            <app-header
                    [sidebarOpened]="sidebarOpened"
                    [isOverMode]="sidebarMode === 'over'"
                    (openSidebar)="onSidebarToggle($event)">
            </app-header>
        </header>
        <div [ngClass]="{'content': showHeaderAndSidebar}" class="app-inside">
            <router-outlet></router-outlet>
        </div>
    </main>
</mat-drawer-container>

<main id="login">
    <router-outlet name="login"></router-outlet>
    <router-outlet name="reset-password"></router-outlet>
</main>

<main *ngIf="showHeaderOnly" id="reports">
    <header class="sticky-top bg-white">
        <app-header
                [showHeaderOnly]=true
                [sidebarOpened]="false"
                [isOverMode]="sidebarMode === 'over'">
        </app-header>
    </header>
    <div class="app-inside">
        <router-outlet></router-outlet>
    </div>
</main>
