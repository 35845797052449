import { Pipe, PipeTransform } from '@angular/core';
import { OrderBy, TableParam } from '../../classes/table-param';
import { getDefaultTableParams } from '../../utils/table-param.util';
import { Routes } from '../../config/app.constant';

@Pipe({
  name: 'withQueryParams',
})
export class WithQueryParamsPipe implements PipeTransform {
  transform(url: string): TableParam | null {
    const routesWithoutQueryParams: string[] = [
      Routes.administrators,
      Routes.headOfSpecialisations,
      Routes.departmentAdministrators,
      Routes.subjectVersionStatuses,
      Routes.subjectResponsibles,
      Routes.activityCategories,
      Routes.curriculumVersionStatuses,
      Routes.subjectVersionSemesterStatuses,
      Routes.requirementForms,
      Routes.activityRates,
      Routes.activityTypes,
      Routes.sites,
      Routes.workSchedules,
      Routes.reportBugs,
      Routes.trainingLevels,
      Routes.trainingLanguages,
    ];

    if (routesWithoutQueryParams.includes(url)) {
      return null;
    }

    if (url === Routes.bugReports) {
      return getDefaultTableParams('id', OrderBy.Desc);
    }

    return getDefaultTableParams();
  }
}
