import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ParamArray } from '../../../classes/param-array';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  generateFullUrl(url: string): string {
    return environment.apiUrl + url;
  }

  generateFullShibbolethUrl(url: string): string {
    return environment.authUrl + url;
  }

  generateFullEmailAuthUlr(url: string): string {
    return environment.emailAuthUrl + url;
  }

  generateUrlQueryParams(
    urlParams: Record<string, string> = null,
    paramArray: ParamArray<any>[] = []
  ): HttpParams {
    let params = new HttpParams();

    if (urlParams) {
      Object.keys(urlParams).forEach((property: string) => {
        if (property in urlParams) {
          params = params.append(property, urlParams[property]);
        }
      });
    }

    if (Array.isArray(paramArray)) {
      paramArray.forEach((param) => {
        if (Array.isArray(param.value)) {
          param.value.forEach((value) => {
            params = params.append(`${param.key}[]`, value);
          });
        }
      });
    }

    return params;
  }

  get(url: string, urlParams: Record<string, string> = null, paramArray: ParamArray<any>[] = []): Observable<any> {
    return this.http.get(this.generateFullUrl(url), {
      params: this.generateUrlQueryParams(urlParams, paramArray),
    });
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(this.generateFullUrl(url), data);
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(this.generateFullUrl(url), data);
  }

  patch(url: string, data: any): Observable<any> {
    return this.http.patch(this.generateFullUrl(url), data);
  }

  delete(url: string): Observable<any> {
    return this.http.delete(this.generateFullUrl(url));
  }

  sendRequest(request: HttpRequest<any>): Observable<any> {
    return this.http.request(request);
  }

  getShibboleth(url: string, urlParams: Record<string, string> = {}): Observable<any> {
    return this.http.get(this.generateFullShibbolethUrl(url), {
      params: this.generateUrlQueryParams(urlParams),
    });
  }

  postEmailAuth(url: string, data: any): Observable<any> {
    return this.http.post(this.generateFullEmailAuthUlr(url), data);
  }
}
