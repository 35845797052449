import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { Endpoints } from '../../../config/app.constant';
import { Department } from '../classes/department';
import { ObjectData } from '../../../classes/object-data';
import { TableParam } from '../../../classes/table-param';
import { tableParamToQueryParam } from '../../../utils/table-param.util';
import { DepartmentAdministrator } from '../../department-administrators/classes/department-administrator';
import { DepartmentAdministratorForm } from '../../department-administrators/classes/department-administrator-form';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  constructor(private httpService: HttpService) {}

  getDepartments(tableParams?: TableParam): Observable<ObjectData<Department>> {
    return this.httpService.get(
      Endpoints.departments,
      tableParams ? tableParamToQueryParam(tableParams) : { pageSize: '1000' }
    );
  }

  createDepartment(department: Department): Observable<Department> {
    return this.httpService.post(Endpoints.departments, department);
  }

  editDepartment(id: number, department: Department): Observable<Department> {
    return this.httpService.put(`${Endpoints.departments}/${id}`, department);
  }

  manageDepartment(department: Department, id?: number): Observable<Department> {
    return id ? this.editDepartment(id, department) : this.createDepartment(department);
  }

  getDepartmentAdministrators(departmentId: number): Observable<ObjectData<DepartmentAdministrator>> {
    return this.httpService.get(`/departments/${departmentId}/department-administrators`);
  }

  createDepartmentAdministrator(
    departmentAdministrator: DepartmentAdministratorForm
  ): Observable<ObjectData<DepartmentAdministrator>> {
    return this.httpService.post('/department-administrators', departmentAdministrator);
  }

  editDepartmentAdministrator(
    id: number,
    departmentAdministrator: DepartmentAdministratorForm
  ): Observable<ObjectData<DepartmentAdministrator>> {
    return this.httpService.put(`/department-administrators/${id}`, departmentAdministrator);
  }

  manageDepartmentAdministrators(
    departmentAdministrator: DepartmentAdministratorForm,
    id?: number
  ): Observable<ObjectData<DepartmentAdministrator>> {
    return id
      ? this.editDepartmentAdministrator(id, departmentAdministrator)
      : this.createDepartmentAdministrator(departmentAdministrator);
  }

  deleteDepartmentAdministrator(id: number): Observable<any> {
    return this.httpService.delete(`/department-administrators/${id}`);
  }
}
