import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { MatDrawer } from '@angular/material/sidenav';
import { Module } from '../../../pages/homepage/classes/modules';
import { AdminModules, Modules, Routes } from '../../../config/app.constant';
import { Router } from '@angular/router';
import { UserData } from '../../../classes/user-data';
import { AuthService } from '../../modules/auth/auth.service';
import { TableParam } from '../../../classes/table-param';
import { getDefaultTableParams } from '../../../utils/table-param.util';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() drawer: MatDrawer;
  @Output() toggleSidebar = new EventEmitter<boolean>();

  modules: Set<Module> = new Set<Module>(Modules);
  user: UserData;
  adminModules: Module[] = AdminModules;
  tableParams: TableParam = getDefaultTableParams();

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getLoggedInUser();

    if (this.user.additionalInfoRequired || !this.user.selectedRole) {
      this.modules = new Set();
    } else {
      this.adminModules.forEach((module: Module) => {
        if (module.roles.includes(this.user.selectedRole)) {
          this.modules.add(module);
        }
      });
    }

    this.sortModules();
    this.listenToLanguageChange();
  }

  sortModules(): void {
    const sortedArray = Array.from(this.modules);

    sortedArray.sort((a, b) =>
      this.translateService.instant(a.title).localeCompare(this.translateService.instant(b.title))
    );

    this.modules = new Set(sortedArray);
  }

  listenToLanguageChange(): void {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.sortModules();
      },
    });
  }

  toggle(): void {
    this.toggleSidebar.emit(!this.drawer.opened);
  }

  goToHome(): void {
    const currentRoute = window.location.pathname;

    if (currentRoute !== Routes.home) {
      this.router.navigate([Routes.home]);
    } else {
      window.location.reload();
    }
  }
}
