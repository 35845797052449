<ng-select
    #select
    class="custom"
    appearance="outline"
    [addTag]="addTag"
    [appendTo]="appendTo"
    [bindLabel]="label"
    [bindValue]="value"
    [minTermLength]="3"
    [clearable]="clearable"
    [notFoundText]="!isLoading ? (notFoundText | translate) : ''"
    [dropdownPosition]="dropdownPosition"
    [formControl]="control"
    [hideSelected]="hideSelected"
    [items]="items"
    [loading]="isLoading"
    [multiple]="isMultiple"
    [loadingText]="'Common.Loading' | translate | titlecase"
    [placeholder]="placeholder | translate | titlecase"
    [required]="required"
    [searchable]="searchable"
    [virtualScroll]="virtualScroll"
    (search)="search.emit($event)"
    (add)="add.emit($event)"
    (clear)="clear.emit($event)"
    (scroll)="scrollSelect.emit($event)"
    (change)="emitChange.emit($event);blurAfterSelect && select.blur()"
    (remove)="remove.emit($event)">
    <ng-container *ngIf="template">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <ng-template
                    [ngTemplateOutlet]="template"
                    [ngTemplateOutletContext]="{ item: item, clear: clear }">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="!template">
        <ng-option *ngFor="let item of items" [value]="item[value]">
            {{ item[label] }}
        </ng-option>
    </ng-container>
</ng-select>