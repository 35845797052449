export enum Language {
  Hu = 'hu',
  En = 'en',
}

export class LanguageConfig {
  static readonly LANGUAGES = [Language.Hu, Language.En];
  static readonly DEFAULT_LANGUAGE = Language.Hu;
}

export const AvailableLanguages = [
  {
    slug: Language.Hu,
    label: 'Common.LangHu',
    flag: 'assets/image/hu.svg',
    alt: 'flag_hu',
  },
  {
    slug: Language.En,
    label: 'Common.LangEn',
    flag: 'assets/image/en.svg',
    alt: 'flag_en',
  },
];
