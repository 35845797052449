import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss'],
})
export class CancelButtonComponent {
  @Input() disabled: boolean;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
}
