import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SharedModule } from '../../shared.module';
import { AuthService } from '../../modules/auth/auth.service';
import { Routes } from '../../../config/app.constant';
import { Router } from '@angular/router';
import { UserData } from '../../../classes/user-data';

@UntilDestroy()
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() sidebarOpened: boolean = false;
  @Input() isOverMode: boolean = false;
  @Input() showHeaderOnly: boolean = false;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  userData: UserData;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.userData = this.authService.getLoggedInUser();
  }

  logOut(): void {
    this.authService.logout();
  }

  onOpenSidebar(): void {
    this.openSidebar.emit(true);
  }

  goToHome(): void {
    const currentRoute = window.location.pathname;
    if (currentRoute !== Routes.home) {
      if (this.showHeaderOnly) {
        window.open(Routes.home);
      } else {
        this.router.navigate([Routes.home]);
      }
    } else {
      window.location.reload();
    }
  }
}
