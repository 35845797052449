import { Component, OnInit, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectComponent } from '../select/select.component';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserData } from '../../../classes/user-data';
import { Role } from '../../../classes/roles';
import { ActionMessageService } from '../../services/action-message.service';
import { AuthService } from '../../modules/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-select-role',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SelectComponent,
    SaveButtonComponent,
    MatFormFieldModule,
  ],
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.scss'],
})
export class SelectRoleComponent implements OnInit {
  title: string = 'Login.SelectRole';
  isLoading: boolean = true;
  isSaving: boolean = false;

  selectRoleForm: FormGroup;
  roleControl: FormControl<string>;

  roles: any[] = [];

  user: UserData;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private actionMessageService: ActionMessageService,
    @Optional() private dialogRef: MatDialogRef<SelectRoleComponent>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getLoggedInUser();

    this.user.roles.forEach((role) => {
      switch (role) {
        case Role.Admin: {
          this.roles.push({
            id: Role.Admin,
            name: this.translateService.instant('Login.Admin'),
          });
          break;
        }
        case Role.Employee: {
          this.roles.push({
            id: Role.Employee,
            name: this.translateService.instant('Login.Employee'),
          });
          break;
        }
        case Role.HeadOfSpecialisation: {
          this.roles.push({
            id: Role.HeadOfSpecialisation,
            name: this.translateService.instant('Login.HeadOfSpecialisation'),
          });
          break;
        }
        case Role.SubjectResponsibleTeacher: {
          this.roles.push({
            id: Role.SubjectResponsibleTeacher,
            name: this.translateService.instant('Login.SubjectResponsible'),
          });
          break;
        }
        case Role.DepartmentAdministrator: {
          this.roles.push({
            id: Role.DepartmentAdministrator,
            name: this.translateService.instant('Login.DepartmentAdministrator'),
          });
          break;
        }
        default: {
          this.roles.push({
            id: Role.Anyone,
            name: this.translateService.instant('Login.Anyone'),
          });
          break;
        }
      }
    });

    this.initSelectRoleForm();
  }

  initSelectRoleForm(): void {
    this.roleControl = new FormControl<string>(
      this.roles.find((role) => role.id === Role.SubjectResponsibleTeacher)?.id || this.roles[0].id,
      [Validators.required]
    );

    this.selectRoleForm = this.formBuilder.nonNullable.group({
      selectedRole: this.roleControl,
    });
  }

  save(): void {
    if (this.selectRoleForm.valid) {
      this.isSaving = true;

      this.roleControl.disable();

      this.authService
        .selectRole(this.roleControl.value)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (response) => {
            localStorage.setItem('token', response.token);

            this.actionMessageService.showSelectRoleSuccess();
            setTimeout(() => {
              this.dialogRef.close();
            }, 2000);
          },
        });
    }
  }
}
