<div class="modal-container position-relative additional-info-modal">
    <div class="modal-container__title mb-3">
        {{ title | translate }}
    </div>
    <div *ngIf="!isLoading" class="modal-content">
        <form [formGroup]="additionalInfoForm" class="modal-container__form">
            <div class="select-wrapper mb-2">
                <app-select
                        [control]="departmentControl"
                        [placeholder]="'Teacher.Department'"
                        [searchable]="true"
                        [items]="departments"
                        [required]="true">
                </app-select>
                <mat-error>
                    {{ departmentError | translate }}
                </mat-error>
            </div>
            <div class="select-wrapper mb-2">
                <app-select
                        [control]="teacherStatusControl"
                        [placeholder]="'Teacher.TeacherStatus'"
                        [searchable]="true"
                        [items]="teacherStatuses"
                        [required]="true">
                </app-select>
                <mat-error>
                    {{ teacherStatusError | translate }}
                </mat-error>
            </div>
        </form>
        <div class="action-buttons mt-3">
            <app-save-button
                    [disabled]="!this.additionalInfoForm.valid || isSaving" (save)="save()"
                    [text]="'Common.Confirm'">
            </app-save-button>
        </div>
    </div>

    <div *ngIf="isLoading" class="modal-content">
        <div class="loader">
            <mat-progress-spinner
                    mode="indeterminate"
                    diameter="50">
            </mat-progress-spinner>
        </div>
    </div>
</div>