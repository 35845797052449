<nav class="sidenav">
    <div class="sidenav-top">
        <div (click)="goToHome()" class="sidenav-top__icon-title">
            <img src="assets/image/sze_logo2.svg" alt="Sze logo"/>
            <span class="title">
                {{ 'Common.AppTitle' | translate }}
            </span>
        </div>
        <button class="sidenav-close-icon" mat-icon-button (click)="toggle()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <ng-container *ngFor="let item of modules" class="items">
        <div
            class="sidenav-item w-100"
            [routerLinkActiveOptions]="{queryParams: 'ignored',matrixParams: 'exact',paths: 'exact',fragment: 'exact'}"
            [routerLink]="item.url"
            [queryParams]="item.url | withQueryParams"
            #rla="routerLinkActive" routerLinkActive="active-link" [ngClass]="{'active-link': rla.isActive}">
            <div class="sidenav-item__content">
                <div class="sidenav-item__content--icon">
                    <mat-icon>{{ item.icon }}</mat-icon>
                </div>
                <div class="sidenav-item__content--text ms-3">{{ item.title | translate }}</div>
            </div>
        </div>

    </ng-container>
    <div class="sidenav-bottom">
    </div>
</nav>
