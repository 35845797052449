import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../services/language.service';
import { LoaderService } from '../loader/services/loader.service';
import { Endpoints } from '../../../config/app.constant';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(private languageService: LanguageService, private loader: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestClone: HttpRequest<any> = request.clone();

    const apiCall: boolean = requestClone.urlWithParams.startsWith(environment.apiUrl);
    const logOutCall: boolean = requestClone.urlWithParams.startsWith(`${environment.authUrl}/shibboleth-logout`);
    const bugReport: boolean =
      requestClone.urlWithParams.includes(Endpoints.reportBugs) && requestClone.method === 'POST';

    if ((apiCall || logOutCall) && !bugReport) {
      requestClone = this.setRequestHeaders(requestClone, 'application/json');
    }

    if (bugReport) {
      requestClone = this.setRequestHeaders(requestClone);
    }

    return next.handle(requestClone);
  }

  private setRequestHeaders(req: HttpRequest<any>, contentType?: string): HttpRequest<any> {
    let headers: { [key: string]: string } = {
      'Accept-Language': this.languageService.currentLang,
    };

    if (contentType) {
      headers = {
        ...headers,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
      };
    }

    if (localStorage.getItem('token')) {
      headers = { ...headers, Authorization: `Bearer ${localStorage.getItem('token')}` };
    }

    return req.clone({
      setHeaders: headers,
      withCredentials: false, // no credentials yet
    });
  }
}
