import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/modules/http/http.service';
import { TableParam } from '../../../classes/table-param';
import { Observable } from 'rxjs';
import { ObjectData } from '../../../classes/object-data';
import { Endpoints } from '../../../config/app.constant';
import { tableParamToQueryParam } from '../../../utils/table-param.util';
import { TeacherStatus } from '../classes/teacher-status';

@Injectable({
  providedIn: 'root',
})
export class TeacherStatusesService {
  constructor(private httpService: HttpService) {}

  getTeacherStatuses(tableParams?: TableParam): Observable<ObjectData<TeacherStatus>> {
    return this.httpService.get(
      Endpoints.teacherStatuses,
      tableParams ? tableParamToQueryParam(tableParams) : { pageSize: '1000' }
    );
  }

  createTeacherStatus(teacherStatus: TeacherStatus): Observable<TeacherStatus> {
    return this.httpService.post(Endpoints.teacherStatuses, teacherStatus);
  }

  editTeacherStatus(id: number, teacherStatus: TeacherStatus): Observable<TeacherStatus> {
    return this.httpService.put(`${Endpoints.teacherStatuses}/${id}`, teacherStatus);
  }

  manageTeacherStatus(teacherStatus: TeacherStatus, id?: number): Observable<TeacherStatus> {
    return id ? this.editTeacherStatus(id, teacherStatus) : this.createTeacherStatus(teacherStatus);
  }
}
