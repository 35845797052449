import { Component, OnInit, Renderer2 } from '@angular/core';
import { LoaderService, LoaderState } from './services/loader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  showLoader: boolean;

  constructor(private loaderService: LoaderService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.loaderService.loaderState.pipe(untilDestroyed(this)).subscribe((state: LoaderState) => {
      this.showLoader = state.show;

      this.toggleScrollBlockToHtml();
    });
  }

  private toggleScrollBlockToHtml(): void {
    if (this.showLoader) {
      this.renderer.addClass(document.body, 'cdk-global-scrollblock');
    } else {
      this.renderer.removeClass(document.body, 'cdk-global-scrollblock');
    }
  }
}
