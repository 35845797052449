import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError, delay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, filter, Observable, of, throwError } from 'rxjs';
import { LoaderService } from '../loader/services/loader.service';
import { MainConstants } from '../../../config/app.constant';
import { AuthService } from '../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ResponseInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private httpService: HttpService,
    private loader: LoaderService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiCall = req.urlWithParams.startsWith(environment.apiUrl);
    const logOutCall = req.urlWithParams.startsWith(`${environment.authUrl}/shibboleth-logout`);

    const requestClone = req.clone();

    return next.handle(requestClone).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && (apiCall || logOutCall)) {
          // this.loader.hideLoaderForUrl(requestClone.url);
        }
      }),
      catchError((error) => {
        if (apiCall || logOutCall) {
          // this.loader.hideLoaderForUrl(requestClone.url);
        }

        if (error instanceof HttpErrorResponse) {
          return this.handleError(error, req, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (error.status === 401) {
      const skipRefresh: boolean = MainConstants.refreshSkippedRoutes.some((route) => request.url.includes(route));

      if (skipRefresh) {
        this.authService.logout();

        return throwError(error);
      }

      return this.handleRefresh(request, next);
    } else if (error.status >= 500) {
      this.toastrService.error(this.translateService.instant('Common.Error.ServerError'));
    }

    return throwError(error);
  }

  private handleRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap(() => {
          return next.handle(request);
        })
      );
    }

    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    return of(null).pipe(
      delay(400),
      switchMap(() => {
        return this.authService.refreshToken().pipe(
          switchMap(({ token }) => {
            this.isRefreshing = false;
            localStorage.setItem('token', token);

            this.refreshTokenSubject.next(token);

            return this.httpService.sendRequest(request);
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
      })
    );
  }
}
