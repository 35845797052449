import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableLanguages, Language } from '../../../config/language.config';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LanguageService } from '../../services/language.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Observable, of } from 'rxjs';
import { LangShortNameToLongNamePipe } from '../../pipes/lang-short-name-to-long-name.pipe';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    LangShortNameToLongNamePipe,
  ],
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  @Input() isColorDark: boolean = false;
  menuOpened: boolean = false;
  availableLanguages = AvailableLanguages;
  currentLanguage$: Observable<Language>;

  constructor(private languageService: LanguageService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentLanguage$ = of(this.languageService.getCurrentLanguage());
  }

  changeLanguage(language: Language): void {
    this.translateService.use(language);
    this.languageService.saveCurrentLanguage(language);
    this.currentLanguage$ = of(language);
  }

  toggleMenuOpenedState(): void {
    this.menuOpened = !this.menuOpened;
  }
}
